import React from 'react'
import styled from 'styled-components'
import { Large, colors } from 'js-components'
import Modal from '../Modal'

import Q from './Thumbnails/q.jpg'
import It from './Thumbnails/it.jpg'
import Sales from './Thumbnails/sales.jpg'
import Evan from './Thumbnails/evan.jpg'
import Explainer from './Thumbnails/explainer.jpg'
import Twilio from './Thumbnails/twilio.jpg'

import Analytics from './Thumbnails/analytics.png'
import Auto from './Thumbnails/auto.png'
import Contacts from './Thumbnails/contacts.png'
import Fields from './Thumbnails/fields.png'
import Find from './Thumbnails/find.png'
import Opp from './Thumbnails/opp.png'
import Robots from './Thumbnails/robots.png'

import Play from './Icons/play.svg'

// type Props = {
//   background?: string,
//   videoLink?: string,
//   caption?: string,
//   opacity?: number,
//   gradientType?: string,
// }

const getBackgroundUrl = (background) => {
  switch (background) {
    default:
    case 'Q':
      return Q
    case 'sales':
      return Sales
    case 'it':
      return It
    case 'explainer':
      return Explainer
    case 'evan':
      return Evan
    case 'twilio':
      return Twilio
      case 'Auto':
      return Auto
    case 'Analytics':
      return Analytics
    case 'Find':
      return Find
    case 'Contacts':
      return Contacts
    case 'Fields':
      return Fields
    case 'Opp':
      return Opp
    case 'robots':
      return Robots
  }
}

const getGradientType = (gradientType) => {
  switch (gradientType) {
    case 'dark':
      return 'linear-gradient(to bottom right, rgba(0,0,0,0.6), rgba(0,0,0,0.7))';
    case 'darkMoreTransparent':
      return 'linear-gradient(to bottom right, rgba(0,0,0,0.2), rgba(0,0,0,0.7))';
    default:
    case 'light':
      return 'linear-gradient(to bottom right, rgba(255,255,255,0.4), rgba(255,255,255,0.9))';
  }
}

function Background(props) {
  const { children, background, backgroundSize, opacity } = props

  return (
    <Container>
      <Modal
        contentStyle={{
          width: '90%',
          height: '90%',
          marginTop: '64pt'
        }}
        button={({ onClick }) => (
          <Large
            as="a"
            p={1}
            pr={3}
            color={'black'}
            onClick={onClick}
          >
          <Wrapper>
            <VideoFrame>
              <VideoFrameBackground
                background={background}
                backgroundSize={backgroundSize}
                opacity={opacity}
              />
              <GradientContainer {...props}>
                <PlayIcon src={Play} alt="Play"/>
              </GradientContainer>
              {children}
            </VideoFrame>
            <VideoCaption color={colors.trulyBlue}>{props.caption}</VideoCaption>
          </Wrapper>
          </Large>
        )}
        modalContent={() => <Iframe src={props.videoLink} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen/>}
      />
    </Container>
  )
}

Background.defaultProps = {
  gradientType: 'light',
  backgroundSize: 'fill',
  backgroundRepeat: 'none',
  caption: 'Watch The Video',
  videoLink: 'https://player.vimeo.com/video/264697195?autoplay=1',
  background: 'hero',
  opacity: 1,
}

export default Background

const Container = styled.div`
  margin: 0;
  display: flex;
  flex-align: center;
  justify-content: center;
  `;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const VideoCaption = styled(Large)`
  margin-top: 16pt;
  font-weight: bold;
`

const Wrapper = styled.div`
  width: 260px;
  margin: 0 8px;
  text-align: center;
`

const VideoFrame = styled.div`
  border-radius: 8pt;
  width: 260px;
  height: 180px;
  position: relative;
  font-family: 'Josefin Sans';
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0pt 4pt 16pt rgba(0,0,0,0.05);
  transition 0.4s all;
  pointer-events: none;

  ${Wrapper}:hover & {
    box-shadow: 0pt 4pt 16pt rgba(0,0,0,0.1);
    transition 0.2s all;
    transform: scale(1.05);
    border-radius: 8pt;
  }
`

const GradientContainer = styled.div`

  width: 260px;
  height: 180px;
  position: absolute;

  opacity: 0.7;

  z-index: -1;

  transition 0.4s opacity;

  ${props =>
    `background: ${getGradientType(props.gradientType)}`};

  ${Wrapper}:hover & {
    opacity: 1.0;
    transition 0.2s opacity;
  }
`

const PlayIcon = styled.img`

  width: 42pt;
  height: 42pt;
  margin-top: 45pt;
  transition 0.4s transform;

  ${Wrapper}:hover & {
    transform: scale(1.1);
    transition 0.2s transform;
  }
`


const VideoFrameBackground = styled.div`
  background-attachment: scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: ${props => props.opacity};
  top: 0;
  position: absolute;
  width: 260px;
  height: 180px;
  z-index: -1;

  ${props =>
    props.background &&
    props.background.length &&
    `background-image: url('${getBackgroundUrl(props.background)}')`};
`
